import { PageResult } from "../../lib/types"
import "@fontsource/exo-2"
import { Helmet } from "react-helmet"
import Modules from "../Modules/Modules"

export interface PageComponentProps extends PageResult {}

const Page = ({ id, title, pageComponents }: PageComponentProps) => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={pageComponents.seoDescription || ""} />
      {/* <link rel="canonical" href="http://mysite.com/example" /> */}
    </Helmet>
    <div>
      <Modules
        modules={pageComponents.modules}
        fullpageScroll={!!pageComponents.fullpagescroll}
      />
    </div>
  </div>
)

export default Page
