import { Fragment, useState } from "react"
import { ModuleTextPhoto as ResProps } from "../../../lib/types"
import Image from "../../Ui/Image/Image"
import LayoutInner from "../../Ui/LayoutInner/LayoutInner"
import Text from "../../Ui/Text/Text"
import { useAnimations } from "./hooks"

import * as styles from "./ModuleTextPhoto.module.scss"

interface Props extends ResProps {}

const ModuleTextPhoto = ({ headline, blocks, image }: Props) => {
  const { src, srcWebp } = image?.localFile.childImageSharp.fixed || {
    src: false,
    srcWebp: false,
  }
  const alt = image?.altText || false

  const { leftRef, centerRef, rightRef } = useAnimations()

  const getBlock = (id: number): JSX.Element | null => {
    const block = blocks[id] || false
    if (!block) {
      return null
    }
    return (
      <div className={styles.block}>
        <Text type="H3" color="Brandtwo">
          {block.headline}
        </Text>
        <Text color="Brandone" isEditor isHtml>
          {block.text}
        </Text>
      </div>
    )
  }

  return (
    <LayoutInner>
      <div className={styles.base}>
        <div className={styles.grid}>
          <div className={styles.headline}>
            <h2>
              <Text color="Brandtwo" type="H2">
                <span>// </span>
                <span>{headline}</span>
              </Text>
            </h2>
          </div>
          <div className={styles.left} ref={leftRef}>
            {getBlock(0)}
          </div>
          <div className={styles.image} ref={centerRef}>
            {image &&
              ((
                <Image
                  src={src as string}
                  srcWebp={srcWebp as string}
                  alt={alt as string}
                  cover
                />
              ) ||
                null)}
          </div>
          <div className={styles.right} ref={rightRef}>
            {getBlock(1)}
          </div>
          <div className={styles.footer} />
        </div>
      </div>
    </LayoutInner>
  )
}

export default ModuleTextPhoto
