import { Link } from "gatsby"
import gsap from "gsap"
import { useEffect, useRef } from "react"
import { ContactResult } from "../../../lib/types"
import { isDesktop } from "../../../utils"
import CutBox from "../../Ui/CutBox/CutBox"
import Icon from "../../Ui/Icon/Icon"
import Text from "../../Ui/Text/Text"
import * as styles from "./ContactBoxes.module.scss"

interface Props {
  contact: ContactResult
}

const ContactBoxes = ({ contact }: Props) => {
  const baseRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (baseRef.current) {
      const tl = gsap.timeline({ paused: true })
      tl.add("start")
      tl.to(baseRef.current, {
        opacity: 1,
        transform: "translateX(75%)",
      })
      tl.add("middle")
      tl.to(baseRef.current, {
        opacity: 1,
        transform: "translateX(0%)",
      })
      tl.add("end")

      tl.tweenTo("middle")

      const open = () => {
        tl.tweenTo("end")
      }
      const close = () => {
        tl.tweenTo("middle")
      }

      const onClick = (e: MouseEvent) => {
        const wasClicked =
          e.composedPath().indexOf(baseRef.current as HTMLDivElement) !== -1

        if (wasClicked) {
          open()
        } else {
          close()
        }
      }

      if (isDesktop) {
        baseRef.current.addEventListener("mouseenter", open)
        baseRef.current.addEventListener("mouseleave", close)
      } else {
        window.addEventListener("click", onClick)
      }

      return () => {
        baseRef.current?.removeEventListener("mouseenter", open)
        baseRef.current?.removeEventListener("mouseleave", close)
        baseRef.current?.removeEventListener("click", open)
        window.removeEventListener("click", onClick)
      }
    }
  }, [baseRef.current])

  return (
    <div className={styles.boxes} ref={baseRef}>
      <div className={styles.box}>
        <div className={styles.boxIcon}>
          <Icon icon="email" color="white" size="30" />
        </div>
        <a href={`mailto:${contact.mail}`}>
          <div className={styles.boxText}>
            <Text>{contact.mail}</Text>
          </div>
        </a>
      </div>
      <div className={styles.box}>
        <div className={styles.boxIcon}>
          <Icon icon="call" color="white" size="30" />
        </div>
        <a href={`tel:${contact.tel}`}>
          <div className={styles.boxText}>
            <Text>{contact.tel}</Text>
          </div>
        </a>
      </div>
    </div>
  )
}

export default ContactBoxes
