import * as styles from "./Image.module.scss"
import c from "classnames"

interface Props {
  srcWebp?: string
  src: string
  alt: string
  cover?: boolean
}

const Image = ({ srcWebp, src, alt, cover }: Props) => (
  <picture
    className={c(styles.base, {
      [styles.cover]: cover,
    })}
  >
    <source srcSet={srcWebp} type="image/webp" />
    <source srcSet={src} />
    <img src={src} alt={alt} />
  </picture>
)

export default Image
