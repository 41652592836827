import Text from "../../../Ui/Text/Text"
import c from "classnames"
import * as styles from "./LeftTitleBar.module.scss"
import CutBox from "../../../Ui/CutBox/CutBox"

interface Props {
  children: string
  isActive?: boolean
}

const LeftTitleBar = ({ isActive, children }: Props) => (
  <div
    className={c([styles.base], {
      [styles.active]: isActive,
    })}
  >
    <CutBox
      theme={isActive ? "Highlight" : "Black"}
      showOnBottomRight={false}
      hoverAnimation
    >
      <div className={styles.inner}>
        <Text type="Body" color={isActive ? "Bg" : "Brandone"}>
          {children}
        </Text>
      </div>
    </CutBox>
  </div>
)

export default LeftTitleBar
