import * as styles from "./Text.module.scss"
import c from "classnames"

interface Props {
  children: JSX.Element | JSX.Element[] | string
  type?: "Huge" | "Iconmega" | "H1" | "H2" | "H3" | "Body" | "Small"
  color?: "Bg" | "Primary" | "Brandone" | "Brandtwo"
  isHtml?: boolean
  isEditor?: boolean
}

const Text = ({
  children,
  type = "Body",
  color = "Primary",
  isHtml = false,
  isEditor = false,
}: Props) => (
  <div
    className={c([styles[`type${type}`], styles[`color${color}`]], {
      [styles.editor]: isEditor,
    })}
  >
    {isHtml && typeof children === "string" ? (
      <div dangerouslySetInnerHTML={{ __html: children }} />
    ) : (
      children
    )}
  </div>
)

export default Text
