import { Fragment, useEffect, useRef, useState } from "react"
import { ModuleSlicedContent as ResProps } from "../../../lib/types"
import CutBox from "../../Ui/CutBox/CutBox"
import LayoutInner from "../../Ui/LayoutInner/LayoutInner"
import Text from "../../Ui/Text/Text"
import SlicedContent from "./Content/Content"
import LeftTitleBar from "./LeftTitleBar/LeftTitleBar"

import * as styles from "./ModuleSlicedContent.module.scss"

interface Props extends ResProps {}

const ModuleSlicedContent = ({ headline, content, anchor }: Props) => {
  const [selectedSection, setSelectedSection] = useState(0)

  return (
    <LayoutInner>
      <div className={styles.base} id={anchor || undefined}>
        <div className={styles.inner}>
          <h2>
            <div className={styles.headline}>
              <Text type="H2" color="Brandtwo">
                <span>// </span>
                <span>{headline}</span>
              </Text>
            </div>
          </h2>

          <div className={styles.content}>
            <div className={styles.leftBar}>
              {content.map((c, cKey) => (
                <div
                  key={cKey}
                  className={styles.leftTitleContainer}
                  onClick={() => {
                    setSelectedSection(cKey)
                  }}
                >
                  <LeftTitleBar isActive={cKey === selectedSection}>
                    {c.headline}
                  </LeftTitleBar>
                </div>
              ))}
            </div>
            <div className={styles.rightContent}>
              <SlicedContent
                contentBlocks={content}
                selected={selectedSection}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutInner>
  )
}

export default ModuleSlicedContent
