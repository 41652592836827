import { Fragment, useState } from "react"
import { ModuleEditorText as ResProps } from "../../../lib/types"
import LayoutInner from "../../Ui/LayoutInner/LayoutInner"
import Text from "../../Ui/Text/Text"

import * as styles from "./ModuleEditorText.module.scss"

interface Props extends ResProps {}

const ModuleEditorText = ({ text }: Props) => {
  const [selectedSection, setSelectedSection] = useState(0)

  return (
    <LayoutInner>
      <div className={styles.base}>
        <Text isHtml isEditor>
          {text}
        </Text>
      </div>
    </LayoutInner>
  )
}

export default ModuleEditorText
