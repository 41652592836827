import { Fragment, useEffect, useRef } from "react"
import CutBox from "../../../Ui/CutBox/CutBox"
import Text from "../../../Ui/Text/Text"
import * as styles from "./Content.module.scss"
import classNames from "classnames"
import { isMobile } from "../../../../utils"
import gsap from "gsap"

interface Props {
  contentBlocks: {
    headline: string
    content: string
  }[]
  selected: number
}

const SlicedContent = ({ contentBlocks, selected }: Props) => {
  const baseRef = useRef<HTMLDivElement>(null)

  const getOffset = (base: HTMLDivElement, elm: HTMLDivElement): number => {
    const baseTop = base.offsetTop
    const selTop = elm.offsetTop
    return selTop - baseTop
  }

  useEffect(() => {
    if (baseRef.current) {
      const { children } = baseRef.current

      const selectedChild = children[selected] || false
      if (selectedChild) {
        const top = getOffset(baseRef.current, selectedChild as HTMLDivElement)
        baseRef.current.scrollTo({ top, behavior: "smooth" })
      }
    }
  }, [selected, baseRef.current])

  useEffect(() => {
    let buttons: NodeList
    if (baseRef.current) {
      if (isMobile) {
        buttons = baseRef.current.querySelectorAll(
          `.${styles.rightTitle}`
        ) as NodeList
        buttons.forEach((node) => {
          const contentElm = node.parentElement?.querySelector(
            `.${styles.contentBlock}`
          ) as HTMLDivElement

          const tl = gsap.timeline({ paused: true })

          tl.from(contentElm, {
            height: "0",
            marginBottom: "0",
            duration: 0.66,
          })
          let rev = false

          node.addEventListener("click", async () => {
            rev ? tl.reverse() : tl.play()
            rev = !rev
          })
        })
      }
    }

    return () => {
      if (baseRef.current && buttons) {
        buttons.forEach((node) => {
          node.removeEventListener("click", null)
        })
      }
    }
  }, [baseRef.current])

  return (
    <div ref={baseRef} className={styles.base}>
      {contentBlocks.map((c, cKey) => (
        <div
          className={classNames(styles.block, {
            [styles.dark]: cKey % 2,
          })}
          key={cKey}
        >
          <div className={styles.rightTitle}>
            <CutBox theme={cKey % 2 ? "Black" : "OnHiglightBg"}>
              <div className={styles.rightTitleInner}>
                <Text color={cKey % 2 === 0 ? "Bg" : "Brandone"} type="H3">
                  <h3>{c.headline}</h3>
                </Text>
              </div>
            </CutBox>
          </div>
          <div className={styles.contentBlock}>
            <Text isHtml isEditor color={cKey % 2 === 0 ? "Bg" : "Brandone"}>
              {c.content}
            </Text>
          </div>
        </div>
      ))}
    </div>
  )
}

export default SlicedContent
