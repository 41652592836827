import { Link } from "gatsby"
import { MenuResult } from "../../lib/types"
import Text from "../Ui/Text/Text"
import * as styles from "./styles.module.scss"

interface Props {
  menu: MenuResult
}

const Footer = ({ menu }: Props) => (
  <footer className={styles.base}>
    <div className={styles.left}>
      <Text type="Small">
        <span>© Peter Hasitschka, {new Date().getFullYear()}</span>
      </Text>
    </div>
    <div className={styles.right}>
      {menu.menuItems.nodes.map((n, key) => (
        <div key={key}>
          <Text type="Small" isEditor>
            <Link to={n.url}>{n.connectedNode.node.title}</Link>
          </Text>
        </div>
      ))}
    </div>
  </footer>
)

export default Footer
