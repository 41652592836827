import c from "classnames"
import * as styles from "./Icon.module.scss"

import IcoMoon, { IconComponent } from "react-icomoon"
const iconSet = require("../../../styles/icons/selection.json")

interface Props {
  icon: string
  color?: string
  size?: string | number
}
const Icon = (props: Props) => (
  <IcoMoon iconSet={iconSet} {...props} disableFill />
)

export default Icon
