import { Fragment, useEffect, useRef } from "react"
import c from "classnames"
import * as styles from "./CutBox.module.scss"
import { url } from "inspector"
import gsap from "gsap"

interface Props {
  children: JSX.Element
  theme?: "Black" | "Highlight" | "OnHiglightBg"
  bg?: string | null
  shownOnTopLeft?: boolean
  showOnBottomRight?: boolean
  hoverAnimation?: boolean
}

const CutBox = ({
  children,
  theme = "Black",
  bg = null,
  shownOnTopLeft = true,
  showOnBottomRight = true,
  hoverAnimation = false,
}: Props) => {
  const baseRef = useRef<HTMLDivElement | null>(null)

  const getSvg = () => (
    <svg viewBox="0 0 30 50" width="30" height="50">
      <path d="M 0 0 L 30 0 L 0 50 L 0 0"></path>
      <line x1="0" y1="50" x2="30" y2="0"></line>
      <line x1="0" y1="30" x2="18" y2="0"></line>
    </svg>
  )

  useEffect(() => {
    if (baseRef.current && hoverAnimation) {
      const overlayLeft = baseRef.current.querySelector(".js-overlay-left svg")
      const tlLeft = gsap.timeline({ paused: true })
      tlLeft.to(overlayLeft, { x: "-=30", duration: 0.33 })

      const overlayRight = baseRef.current.querySelector(
        ".js-overlay-right svg"
      )
      const tlRight = gsap.timeline({ paused: true })
      if (overlayRight) {
        tlRight.to(overlayRight, { x: "-=30", duration: 0.33 })
      }

      const onHover = () => {
        tlLeft.play()
        tlRight.play()
      }
      const onLeave = () => {
        tlLeft.reverse()
        tlRight.reverse()
      }

      baseRef.current.addEventListener("mouseenter", onHover)
      baseRef.current.addEventListener("mouseleave", onLeave)

      return () => {
        baseRef.current?.removeEventListener("mouseenter", onHover)
        baseRef.current?.removeEventListener("mouseleave", onLeave)
      }
    }
  }, [baseRef.current])

  return (
    <div ref={baseRef} className={c([styles.base, styles[`theme${theme}`]])}>
      <div
        className={styles.backgroundOverlay}
        style={{
          backgroundImage: bg ? `url("${bg}")` : undefined,
        }}
      />
      <Fragment>{children}</Fragment>
      {(shownOnTopLeft && (
        <div className={`${styles.overlay} js-overlay-left`}>{getSvg()}</div>
      )) ||
        null}
      {(showOnBottomRight && (
        <div
          className={`${styles.overlay} ${styles.overlayFlipped} js-overlay-right`}
        >
          {getSvg()}
        </div>
      )) ||
        null}
    </div>
  )
}
export default CutBox
