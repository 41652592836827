import { useRef, useEffect } from "react"
import gsap, { Back, Bounce } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

export const useAnimations = () => {

    const iconRef = useRef<HTMLDivElement | null>(null)
    const blockTopRef = useRef<HTMLDivElement | null>(null)
    const blockBottomRef = useRef<HTMLDivElement | null>(null)
    const boxesRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
    }, [false])


    useEffect(() => {
        let tl: gsap.core.Timeline | undefined;
        if (boxesRef.current) {
            const boxesNodes = boxesRef.current.querySelectorAll('.js-lebox')
            const boxes = gsap.utils.toArray(boxesNodes)

            tl = gsap.timeline()
            tl.add("start")
            tl.to(boxes, {
                stagger: 0.1,
                y: "-=200",
                duration: 0.5,
                ease: Back.easeOut,
                delay: 0.5
            }, "start")
            tl.to(boxes, {
                opacity: 1,
                duration: 2,
                delay: 0.5
            }, "start")
        }


    }, [boxesRef.current])

    useEffect(() => {
        if (iconRef.current) {
            const svgLineElm = iconRef.current.querySelector("#horizontal") as Element
            const parts = iconRef.current.querySelectorAll('.logo-part')

            const tl = gsap.timeline()
            tl.to(parts, { fillOpacity: 1, duration: 0.3, stagger: 0.2, delay: 0.2 })
            tl.to(svgLineElm, { opacity: 1, duration: 0.01 })
            tl.add("afterType")
            tl.from(svgLineElm, {
                scaleX: 0,
                duration: 2.0,
                delay: -0.3,
                ease: Bounce.easeOut,
            }, "afterType")
            tl.play()
        }
    }, [iconRef.current])

    useEffect(() => {
        if (blockTopRef.current && blockBottomRef.current) {
            const tl = gsap.timeline({
                paused: true,
                scrollTrigger: {
                    trigger: blockTopRef.current,
                    start: "top-=100 top",
                    end: "bottom+=600 top",
                    scrub: true,
                },
            })
            tl.add("start")
            const { x: topXFinal, width: topWidth } =
                blockTopRef.current.getBoundingClientRect()
            const { x: bottomXFinal, width: bottomWidthFinal } =
                blockBottomRef.current.getBoundingClientRect()
            tl.to(
                blockTopRef.current,
                {
                    x: 0 - topXFinal - topWidth,
                    opacity: 0,
                    // duration: 0.33,
                },
                "start"
            )
            tl.to(
                blockBottomRef.current,
                {
                    x: bottomXFinal + bottomWidthFinal,
                    opacity: 0,
                    // duration: 0.33,
                },
                "start"
            )
        }
    }, [blockTopRef.current, blockBottomRef.current])


    return { blockTopRef, blockBottomRef, iconRef, boxesRef }
}