import { Fragment, useState } from "react"
import { ModuleHeaderCommon as ResProps } from "../../../lib/types"
import LayoutInner from "../../Ui/LayoutInner/LayoutInner"
import Text from "../../Ui/Text/Text"

import * as styles from "./ModuleHeaderCommon.module.scss"

interface Props extends ResProps {}

const ModuleHeaderCommon = ({ headline, subheadline }: Props) => {
  const [selectedSection, setSelectedSection] = useState(0)

  return (
    <LayoutInner>
      <div className={styles.base}>
        <Text type="H1" color="Brandtwo">
          {headline}
        </Text>
        <Text type="H2">{subheadline}</Text>
      </div>
    </LayoutInner>
  )
}

export default ModuleHeaderCommon
