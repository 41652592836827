import * as styles from "./styles.module.scss"

import Logo from "../../assets/logo.svg"
import { Link } from "gatsby"

const Header = () => (
  <header className={styles.base}>
    <Link to="/">
      <Logo />
    </Link>
  </header>
)

export default Header
