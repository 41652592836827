import * as styles from "./styles.module.scss"
import c from "classnames"

interface Props {
  children: JSX.Element[] | JSX.Element
  fps: boolean
}

const ScrollWrapper = ({ children, fps }: Props) => (
  <div className={c(styles.base, { [styles.fps]: fps })}>{children}</div>
)

export default ScrollWrapper
