import Footer from "../Footer/Footer"
import Header from "../Header/Header"
// import "./styles.module.scss"

import "../../styles/index.global.scss"
import { ContactResult, MenuResult } from "../../lib/types"
import * as styles from "./styles.module.scss"
import ContactBox from "./ContactBoxes/ContactBoxes"

interface Props {
  children: JSX.Element | JSX.Element[]
  showHeader: boolean
  footerMenu: MenuResult
  contact: ContactResult
}

const Layout = ({ children, showHeader, footerMenu, contact }: Props) => (
  <div>
    <div className={styles.layout}>
      {(showHeader && (
        <div className={styles.headerWrapper}>
          <Header />
        </div>
      )) ||
        null}
      <main>{children}</main>
      <div>
        <Footer menu={footerMenu} />
      </div>
    </div>
    <div className={styles.contact}>
      <ContactBox contact={contact} />
    </div>
  </div>
)

export default Layout
