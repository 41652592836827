import { useRef, useEffect } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { isDesktop } from "../../../utils"

export const useAnimations = () => {

    const leftRef = useRef<HTMLDivElement | null>(null)
    const centerRef = useRef<HTMLDivElement | null>(null)
    const rightRef = useRef<HTMLDivElement | null>(null)


    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
    }, [false])


    useEffect(() => {
        if (leftRef.current && isDesktop) {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: leftRef.current,
                    start: "center 75%",
                    end: "center 25%",
                    scrub: true,
                }
            })

            tl.to(leftRef.current, {
                y: '20'
            })

        }
    }, [leftRef.current])

    useEffect(() => {
        if (rightRef.current && isDesktop) {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: rightRef.current,
                    start: "center 75%",
                    end: "center 25%",
                    scrub: true,
                }
            })

            tl.to(rightRef.current, {
                y: '-20'
            })

        }
    }, [rightRef.current])


    return { leftRef, centerRef, rightRef }
}