import * as styles from "./Logo.module.scss"
import "./Logo.global.scss"
import LogoSvg from "../../../assets/logo.svg"
interface Props {
  initVisible: boolean
}

const Logo = ({ initVisible = true }) => (
  <div
    className={`logo-component ${initVisible ? "visible" : ""} ${styles.base}`}
  >
    <LogoSvg />
  </div>
)

export default Logo
