// extracted by mini-css-extract-plugin
export var fWeightThin = "Text-module--f-weight-thin--MyfTH";
export var fWeightExtralight = "Text-module--f-weight-extralight--Wwkta";
export var fWeightLight = "Text-module--f-weight-light--qT6pn";
export var typeSmall = "Text-module--type-small--u54d0";
export var typeBody = "Text-module--type-body--gcyN4";
export var typeH3 = "Text-module--type-h3--Fro5r";
export var fWeightRegular = "Text-module--f-weight-regular--r8cpn";
export var typeH2 = "Text-module--type-h2--qkSrx";
export var typeH1 = "Text-module--type-h1--gl+bp";
export var typeIconmega = "Text-module--type-iconmega--nxc24";
export var typeHuge = "Text-module--type-huge--qwXK4";
export var fWeightMedium = "Text-module--f-weight-medium--OKUvk";
export var editor = "Text-module--editor--J7q5e";
export var colorBg = "Text-module--color-bg--cE49O";
export var colorPrimary = "Text-module--color-primary--ZvEpj";
export var colorBrandone = "Text-module--color-brandone--Fa9XS";
export var colorBrandtwo = "Text-module--color-brandtwo--INYj9";