// extracted by mini-css-extract-plugin
export var fWeightThin = "Content-module--f-weight-thin--dGFZH";
export var fWeightExtralight = "Content-module--f-weight-extralight--SCV61";
export var fWeightLight = "Content-module--f-weight-light--Q2CdU";
export var contentBlock = "Content-module--contentBlock--VIWIM";
export var fWeightRegular = "Content-module--f-weight-regular--isIMX";
export var fWeightMedium = "Content-module--f-weight-medium--ERdzV";
export var base = "Content-module--base--FA3pd";
export var rightTitle = "Content-module--rightTitle--Fz6D1";
export var rightTitleInner = "Content-module--rightTitleInner--zXb8P";
export var block = "Content-module--block--kOyZM";
export var dark = "Content-module--dark--KP8jn";