import { Fragment } from "react"
import { Modules as ModulesInterface } from "../../lib/types"
import ScrollWrapper from "../ScrollWrapper/ScrollWrapper"
import Text from "../Ui/Text/Text"
import ModuleEditorText from "./ModuleEditorText/ModuleEditorText"
import ModuleHeaderCommon from "./ModuleHeaderCommon/ModuleHeaderCommon"
import ModuleHomeIntro from "./ModuleHomeIntro/ModuleHomeIntro"
import ModuleSlicedContent from "./ModuleSlicedContent/ModuleSlicedContent"
import ModuleTextPhoto from "./ModuleTextPhoto/ModuleTextPhoto"

interface Props {
  modules: ModulesInterface
  fullpageScroll: boolean
}

const Modules = ({ modules, fullpageScroll }: Props) => (
  <div style={{}}>
    <ScrollWrapper fps={fullpageScroll}>
      {modules.map((module, mKey) => {
        let moduleComponent = null
        switch (module.fieldGroupName) {
          case "Page_Pagecomponents_Modules_HomeIntro":
            moduleComponent = <ModuleHomeIntro {...module} />
            break
          case "Page_Pagecomponents_Modules_SlicedContent":
            moduleComponent = <ModuleSlicedContent {...module} />
            break
          case "Page_Pagecomponents_Modules_HeaderCommon":
            moduleComponent = <ModuleHeaderCommon {...module} />
            break
          case "Page_Pagecomponents_Modules_EditorText":
            moduleComponent = <ModuleEditorText {...module} />
            break
          case "Page_Pagecomponents_Modules_TextPhoto":
            moduleComponent = <ModuleTextPhoto {...module} />
            break
          default:
            moduleComponent = (
              <Text>MODULE NOT FOUND {(module as any).fieldGroupName}</Text>
            )
        }
        return <Fragment key={mKey}>{moduleComponent}</Fragment>
      })}
    </ScrollWrapper>
  </div>
)

export default Modules
