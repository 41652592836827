import * as styles from "./LayoutInner.module.scss"

interface Props {
  children: JSX.Element
}

const LayoutInner = ({ children }: Props) => (
  <div className={styles.base}>
    <div className={styles.inner}>{children}</div>
  </div>
)

export default LayoutInner
