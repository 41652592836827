import { LocalFileImg, ModuleHomeIntro as ResProps } from "../../../lib/types"
import LayoutInner from "../../Ui/LayoutInner/LayoutInner"
import Logo from "../../Ui/Logo/Logo"
import Text from "../../Ui/Text/Text"

import * as styles from "./ModuleHomeIntro.module.scss"
import Icon from "../../Ui/Icon/Icon"
import CutBox from "../../Ui/CutBox/CutBox"
import { useCallback } from "react"
import { Link } from "gatsby"

import { useAnimations } from "./hooks"

interface Props extends ResProps {}

const ModuleHomeIntro = ({
  preHeadline,
  headline,
  subheadline,
  blocks,
  anchor,
}: Props) => {
  const { iconRef, blockTopRef, blockBottomRef, boxesRef } = useAnimations()

  const leBox = useCallback(
    (title: string, icons: string[], img: LocalFileImg | null, key: number) => {
      const imgSrces = img?.localFile.childImageSharp.fixed || null

      return (
        <div key={key} className={`${styles.boxContainer} js-lebox`}>
          <CutBox bg={imgSrces?.srcWebp}>
            <div className={styles.leBox}>
              <Text type="H1">{title}</Text>
              <Text type="Iconmega">
                <div className={styles.icons}>
                  {icons.map((i, k) => (
                    <div key={k}>
                      <Icon icon={i} size={60} />
                    </div>
                  ))}
                </div>
              </Text>
            </div>
          </CutBox>
        </div>
      )
    },
    [false]
  )

  return (
    <LayoutInner>
      <div className={styles.base} id={anchor || undefined}>
        <div className={styles.inner}>
          <div className={styles.logo} ref={iconRef}>
            <Logo initVisible={false} />
          </div>

          <h1>
            <div ref={blockTopRef}>
              <div className={styles.headlinePre}>
                <Text color="Brandone">{preHeadline}</Text>
              </div>
              <div className={styles.headline}>
                <Text type="H1" color="Primary">
                  {headline}
                </Text>
              </div>
            </div>
            <div ref={blockBottomRef}>
              <Text type="H2" color="Brandtwo">
                <span dangerouslySetInnerHTML={{ __html: subheadline }} />
              </Text>
            </div>
          </h1>

          <div className={styles.boxes} ref={boxesRef}>
            {blocks.map((block, bKey) =>
              leBox(
                block.headline,
                block.icons?.map((i) => i.icon) || [],
                block.bg,
                bKey
              )
            )}
          </div>
        </div>
      </div>
    </LayoutInner>
  )
}

export default ModuleHomeIntro
